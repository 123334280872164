/* ==================================================================================================================
 * OpenGoSim Bluebell: app/bramble/runs/runs.effects.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of as observableOf, Observable } from 'rxjs';
import { debounceTime, tap, catchError, switchMap, map, concatMap } from 'rxjs/operators';

import { NotificationAction } from 'app/shared/services/notifications.service';
import { RestService } from 'app/shared/services/rest.service';

import * as actions from '../actions';
import { FileImport, Run, SubProject, fileImportUrl, runsUrl } from '../interfaces';
import { AppStoreUtils } from '../app.store';

@Injectable()
export class RunsEffects {


  createRun$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.CREATE_RUN),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.post(runsUrl, run).pipe(
        map((response: Run) => new actions.runs.CreateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('CREATE_RUN error', err);
          return observableOf(new NotificationAction({
              title: 'Create Run Failed',
              body: 'Failed to create ' + run.name
            })
          );
        })
      )
    )
  ));


  createChildRun$ = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.CREATE_CHILD_RUN),
    map((action: any) => action.payload),
    switchMap((payload: { parent: Run, run: Run }) =>
      this.rest.post(runsUrl + payload.parent.uuid + '/create_child/', payload.run).pipe(
        tap((newRun: Run) => {
          this.store.dispatch(new actions.runs.Select(newRun));
          this.router.navigate(['/runs', newRun.uuid]);
        }),
        catchError((err: HttpErrorResponse) => {
          console.log('CREATE_CHILD_RUN error', err);
          return observableOf(new NotificationAction({
              title: 'Create Child Run Failed',
              body: 'Failed to create childButton of' + payload.run.name
            })
          );
        })
      )
    )
  ), {dispatch: false});


  deleteRun$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.DELETE_RUN),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.del(runsUrl + run.uuid + '/').pipe(
        map(() => new actions.runs.DeleteSuccess(run)),
        catchError(() => observableOf(new NotificationAction({
          title: 'Delete Run Failed',
          body: 'Failed to delete: ' + run.name
        })))
      )
    )
  ));


  loadRuns$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.LOAD_RUNS),
    tap(() => this.store.dispatch(new actions.runs.LoadStarted())),
    switchMap(() =>
      this.rest.get(runsUrl).pipe(
        map((response: Run[]) => new actions.runs.LoadSuccess(response)),
        catchError((err: HttpErrorResponse) =>
          err.status === 401 ?
            observableOf(new actions.login.RenewTokenFor401(new actions.runs.Load))
            :
            observableOf(new actions.runs.LoadFail(err))
        )
      )
    )
  ));


  updateRun$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.UPDATE_RUN),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.patch(runsUrl + run.uuid + '/', run)
      .pipe(
        map((response: Run) => new actions.runs.UpdateSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UPDATE_RUN error', err);
          return observableOf(new NotificationAction({
              title: 'Update Run Failed',
              body: 'Failed to update ' + run.name
            })
          );
        })
      )
    )
  ));

   loadOneRun$ = createEffect(() => this.actions$.pipe(
    ofType(
      actions.runs.LOAD_ONE_AND_SELECT,
      actions.runs.LOAD_ONE_QUIETLY,
      actions.runs.BACKEND_SAYS_REFRESH_ONE_RUN
    ),
    debounceTime(200),
    switchMap((action: any) =>
      this.rest.get(runsUrl + action.payload + '/').pipe(
        map((response: Run) =>
          action.type === actions.runs.LOAD_ONE_AND_SELECT ?
            new actions.runs.LoadOneAndSelectSuccess(response)
            :
            new actions.runs.LoadOneQuietlySuccess(response)
        ),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            return action.type === actions.runs.LOAD_ONE_AND_SELECT ?
              observableOf(new actions.login.RenewTokenFor401(new actions.runs.LoadOneAndSelect(action.payload)))
              :
              observableOf(new actions.login.RenewTokenFor401(new actions.runs.LoadOneQuietly(action.payload)));
          }
          return action.type === actions.runs.LOAD_ONE_AND_SELECT ?
            observableOf(new actions.runs.LoadOneAndSelectFail(err))
            :
            observableOf(new actions.runs.LoadOneQuietlyFail(err));
        })
      )
    )
  ));

   checkIfProjectNeedsSetting$ = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.LOAD_ONE_AND_SELECT_SUCCESS),
    map((action: any) => action.payload),
    tap((run: Run) => {
      const subProject: SubProject = this.store.getValueOnce(this.store.selectedSubProject);
      if (run && !subProject) {
        this.store.dispatch(new actions.subProjects.Select({uuid: run.sub_project}));
      }
    })
  ), {dispatch: false});

  // If project is deleted, remove all associated runs

  projectDeleteTriggersRefresh: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.subProjects.DELETE_SUBPROJECT_SUCCESS),
    map(() => new actions.runs.RefreshRuns())
  ));

  // File linking and unlinked

  unlinkFileImport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.UNLINK_FILE_IMPORT),
    map((action: any) => action.payload),
    switchMap((fileImport: FileImport) =>
      this.rest.get(fileImportUrl + fileImport.uuid + '/unlink/').pipe(
        map((response: FileImport) => new actions.runs.UnlinkFileImportSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('UNLINK_FILE_IMPORT error', err);
          return observableOf(new NotificationAction({
              title: 'File Import Unlink Failed',
              body: 'Failed to unlink file import: ' + fileImport.filename
            })
          );
        })
      )
    )
  ));


  linkFileImport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.LINK_FILE_IMPORT),
    switchMap((action: any) => {
      const fileImport: FileImport = action.payload.fileImport;
      const fileType: string = action.payload.fileType;
      const uuid: string = action.payload.uuid;
      return this.rest.post(
        fileImportUrl + fileImport.uuid + '/link/',
        Object.assign({file_type: fileType, uuid})
      ).pipe(
        map((response: FileImport) => new actions.runs.LinkFileImportSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('LINK_FILE_IMPORT error', err);
          return observableOf(new NotificationAction({
              title: 'File Import Link Failed',
              body: 'Failed to link file import ' + fileImport.filename
            })
          );
        })
      );
    })
  ));

  // Delete file import


  deleteFileImport$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.DELETE_FILE_IMPORT),
    map((action: any) => action.payload),
    concatMap((fileImport: FileImport) =>
      this.rest.del(fileImportUrl + fileImport.uuid + '/').pipe(
        map((response: FileImport) => new actions.runs.DeleteFileImportSuccess(response)),
        catchError((err: HttpErrorResponse) => {
          console.log('DELETE_FILE_IMPORT error', err);
          return observableOf(new NotificationAction({
              title: 'File Import Delete Failed',
              body: 'Failed to delete file import: ' + fileImport.filename
            })
          );
        })
      )
    )
  ));


  selectRunAfterCreation$ = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.CREATE_RUN_SUCCESS),
    map((action: any) => action.payload),
    tap((run: Run) => this.router.navigate(['/runs', run.uuid]))
  ), {dispatch: false});

  autolinkInputDeck$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.INPUT_DECK_AUTOLINK),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.get(runsUrl + run.uuid + '/autolink_input_deck/').pipe(
        map(response => new actions.runs.Load()),  // TODO: Use returned run to update store
        catchError(() => observableOf(new NotificationAction({
          title: 'Input Deck Autolink Error',
          body: 'Failed to autolink input deck of: ' + run.name
        })))
      )
    )
  ));


  deleteInputDeck$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.INPUT_DECK_DELETE),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.get(runsUrl + run.uuid + '/delete_input_deck/').pipe(
        map(response => new actions.runs.Load()),  // TODO: Use returned run to update store
        catchError(() => observableOf(new NotificationAction({
          title: 'Input Deck Delete Error',
          body: 'Failed to delete input deck of: ' + run.name
        })))
      )
    )
  ));


  runOnTestMachine$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.RUN_ON_TEST_MACHINE),
    switchMap((action: any) =>
      this.rest
        .get(runsUrl + action.payload.run.uuid + '/submit_to_test_machine/?cpu=' + action.payload.cores + '&output_name=' + action.payload.output_name).pipe(
          map((response: any) => {
            if (!!response.uuid) {
              return new actions.runs.RunOnTestMachineSuccess(response);
            } else {
              return new NotificationAction({
                title: 'Run Error',
                body: response.errors
              });
            }
          }),
          catchError(() => observableOf(new NotificationAction({
            title: 'Run on Test Machine Error',
            body: 'Failing to launch: ' + action.payload.run.name
          })))
        )
    )
  ));


  queueOnCluster$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.QUEUE_ON_CLUSTER),
    switchMap((action: any) =>
      this.rest.get([
        runsUrl,
        action.payload.run.uuid,
        '/submit_to_cluster/?cpu=',
        action.payload.cores,
        '&duration=',
        action.payload.duration,
        '&output_name=',
        action.payload.output_name
      ].join('')).pipe(
        map((response: any) => {
          if (!!response.uuid) {
            return new actions.runs.QueueOnClusterSuccess(response);
          }
          return new NotificationAction({
            title: 'Queue Error',
            body: response.errors
          });
        }),
        catchError((err: HttpErrorResponse) => observableOf(
          new NotificationAction({
            title: 'Failed to queue: ' + action.payload.run.name,
            body: !!err.error.detail ? err.error.detail : 'Unknown failure'
          })
        ))
      )
    )
  ));


  stopRunOnCluster$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.STOP_RUN_ON_CLUSTER),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.get(runsUrl + run.uuid + '/stop_on_cluster/').pipe(
        map((response: Run) => new actions.runs.StopRunOnClusterSuccess(response))
      )
    )
  ));


  stopRunOnTestMachine$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(actions.runs.STOP_RUN_ON_TEST_MACHINE),
    map((action: any) => action.payload),
    switchMap((run: Run) =>
      this.rest.get(runsUrl + run.uuid + '/stop_on_test_machine/').pipe(
        map((response: Run) => new actions.runs.StopRunOnTestMachineSuccess(response))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private rest: RestService,
    private router: Router,
    private store: AppStoreUtils) { }

}
